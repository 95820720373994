import React from 'react';
import styled from 'styled-components';

import MissionDesign from '../ServiceContent/Mission';
import Preflight from '../ServiceContent/Preflight';
import Diagnostics from '../ServiceContent/Diagnostics';
import Operations from '../ServiceContent/Operations';

const Group = styled.div`
  color: white;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;

  min-width: 296px;
  max-width: 296px;
`;

const ContentContainer = styled.div`
  background: ${({ theme }) => theme.black};

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  align-items: center;
  justify-items: center;

  padding: 2rem 0;
  min-width: 100%;
  min-height: 100%;

  .group:not(:first-child) {
    margin-top: 2rem;
  }
`;

function ServicePartsStatic(): JSX.Element {
  return (
    <ContentContainer>
      <Group className="group">
        <MissionDesign />
      </Group>
      <Group className="group">
        <Preflight />
      </Group>
      <Group className="group">
        <Diagnostics />
      </Group>
      <Group className="group">
        <Operations />
      </Group>
    </ContentContainer>
  );
}

export default ServicePartsStatic;
