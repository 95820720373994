import styled from 'styled-components';

interface Scroll {
  dark?: boolean;
}

export const Scroll = styled.div<Scroll>`
  background: ${({ dark, theme }) => {
    if (dark) {
      return theme.black;
    }
    return 'white';
  }};

  position: sticky;
  left: 0;
  top: 0;

  height: 100vh;
  width: 100%;

  transition: all 0.5s;
`;

export const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;
  height: 200vh;
`;
