import React from 'react';
import styled from 'styled-components';

import ImageExperiment from 'src/images/services/start.png';
import ImagePhase2 from 'src/images/services/final.png';

const Image = styled.img`
  position: absolute;
  transform: translateZ(0);

  height: 170px;
  width: 170px;

  will-change: top, left;

  z-index: 3;

  @media only screen and (min-width: 768px) {
    height: 300px;
    width: 300px;
  }

  @media only screen and (min-width: 1024px) {
    height: 400px;
    width: 400px;
  }

  @media only screen and (min-width: 1200px) {
    height: 500px;
    width: 500px;
  }

  @media only screen and (min-width: 1500px) {
    height: 550px;
    width: 550px;
  }
`;

interface Images {
  scrollProgress: number;
}

export default React.memo(function ImagesComponent({
  scrollProgress,
}: Images): JSX.Element {
  const imageRef = React.useRef<HTMLImageElement | null>(null);

  const offsetX = 45 * (Math.max(100 - scrollProgress, 0) / 100);
  const offsetY = 45 * (Math.max(100 - scrollProgress, 0) / 100);

  const imageOffsetX =
    (Math.max(scrollProgress, 0) / 100) * ((imageRef.current?.width || 0) / 2);

  const imageOffsetY =
    (Math.max(scrollProgress, 0) / 100) * ((imageRef.current?.height || 0) / 2);

  const phase1Opacity = 1 * (Math.max(100 - scrollProgress, 0) / 100);
  const phase2Opacity = 1 * (Math.max(scrollProgress, 0) / 100);

  return (
    <>
      <Image
        style={{
          opacity: phase1Opacity,
          left: `calc(50% - ${offsetX || 0}% - ${imageOffsetX}px)`,
          top: `calc(50% - ${offsetY || 0}% - ${imageOffsetY}px)`,
        }}
        ref={imageRef}
        src={ImageExperiment}
      />
      <Image
        style={{
          opacity: phase2Opacity,
          left: `calc(50% - ${offsetX || 0}% - ${imageOffsetX}px)`,
          top: `calc(50% - ${offsetY || 0}% - ${imageOffsetY}px)`,
        }}
        src={ImagePhase2}
      />
    </>
  );
});
