import React, { useRef } from 'react';
import { useScrollProgress } from 'src/Utils/hooks';

import { Container, Scroll } from './Styles';
import Content from './Content';
import Indicator from '../Indicator';
import Personalised from './Personalised';

const SPREAD_START = 10;
const SPREAD_END = 70;

function Component(): JSX.Element {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollProgress = useScrollProgress(containerRef);

  return (
    <Container ref={containerRef}>
      <Scroll>
        <Content
          spreadStart={SPREAD_START}
          spreadEnd={SPREAD_END}
          scrollProgress={Math.max(
            Math.min(scrollProgress, SPREAD_END),
            SPREAD_START,
          )}
        />
        <Personalised
          spreadStart={SPREAD_START}
          spreadEnd={SPREAD_END}
          scrollProgress={Math.max(
            Math.min(scrollProgress, SPREAD_END),
            SPREAD_START,
          )}
        />
        <Indicator hidden={scrollProgress >= 80} />
      </Scroll>
    </Container>
  );
}

export default Component;
