import React, { useRef } from 'react';
import { Container, Scroll } from './Styles';
import { useScrollProgress } from 'src/Utils/hooks';

import Explanation from './Explanation';
import Images from './Images';
import Napkin from './Napkin';
import Progress from './Progress';
import Indicator from '../Indicator';
import Title from './Title';

const IMAGE_STOP_POINT = 60;
const INVERT_POINT = 60;

function Component(): JSX.Element {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollProgress = useScrollProgress(containerRef);

  const imageProgress = Math.min(scrollProgress / IMAGE_STOP_POINT, 1) * 100;
  const wordsProgress =
    Math.min(Math.max(scrollProgress - IMAGE_STOP_POINT, 0) / 40, 1) * 100;

  return (
    <Container ref={containerRef}>
      <Scroll dark={scrollProgress > INVERT_POINT}>
        <Title hidden={scrollProgress <= INVERT_POINT} />
        <Explanation hidden={scrollProgress > INVERT_POINT} />
        <Napkin imageStop={IMAGE_STOP_POINT} scrollProgress={scrollProgress} />
        <Images scrollProgress={imageProgress} />
        <Progress scrollProgress={wordsProgress} />
        <Indicator hidden={scrollProgress >= 80} />
      </Scroll>
    </Container>
  );
}

export default Component;
