import React from 'react';
import styled from 'styled-components';

const HiddenBase = styled.div`
  opacity: 1;
  transition: opacity 0.5s;

  &.hidden {
    opacity: 0;
  }
`;

const Divider = styled(HiddenBase)`
  background: white;
  height: 2px;
  width: 32px;
  margin: 0.5rem;

  visibility: hidden;

  @media only screen and (min-width: 768px) {
    margin: 1rem;
    visibility: visible;
  }
`;

const Word = styled(HiddenBase)`
  color: white;
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 1.5rem;
  font-weight: bold;
  white-space: nowrap;

  @media only screen and (min-width: 768px) {
    font-size: 1.5rem;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 2.25rem;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 3rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);

  z-index: 5;

  @media only screen and (min-width: 768px) {
    flex-flow: row;
    bottom: 20%;
  }
`;

interface Progress {
  scrollProgress: number;
}

const Progress = React.memo(function ({
  scrollProgress,
}: Progress): JSX.Element {
  return (
    <Wrapper>
      <Word className={scrollProgress < 20 ? 'hidden' : ''}>Design</Word>
      <Divider className={scrollProgress < 30 ? 'hidden' : ''} />
      <Word className={scrollProgress < 40 ? 'hidden' : ''}>Pre-flight</Word>
      <Divider className={scrollProgress < 50 ? 'hidden' : ''} />
      <Word className={scrollProgress < 60 ? 'hidden' : ''}>Diagnostics</Word>
      <Divider className={scrollProgress < 70 ? 'hidden' : ''} />
      <Word className={scrollProgress < 80 ? 'hidden' : ''}>Operations</Word>
    </Wrapper>
  );
});

export default Progress;
