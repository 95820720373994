import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';

import * as Base from 'src/page-styles/base.styled';

const Title = styled.div`
  color: white;

  display: flex;
  align-items: center;

  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;

  svg {
    margin-left: 1rem;
  }
`;

function Diagnostics(): JSX.Element {
  return (
    <Link to="/diagnostics" style={{ textDecoration: 'none' }}>
      <Title>
        Diagnostics <FaRegArrowAltCircleRight />
      </Title>
      <Base.Paragraph className="paragraph">
        To gain the deepest understanding of your spacecraft (or any other
        complex system) we provide machine learning diagnostics as a platinum
        service for select customers. The ability to rapidly detect damage to
        satellite systems, signals, and performances provide unique advantages
        and risk mitigation.
      </Base.Paragraph>
    </Link>
  );
}

export default Diagnostics;
