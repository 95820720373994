import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';

import * as Base from 'src/page-styles/base.styled';

const Title = styled.div`
  color: white;

  display: flex;
  align-items: center;

  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;

  svg {
    margin-left: 1rem;
  }
`;

function Preflight(): JSX.Element {
  return (
    <Link to="/pre-flight" style={{ textDecoration: 'none' }}>
      <Title>
        Pre-flight <FaRegArrowAltCircleRight />
      </Title>
      <Base.Paragraph className="paragraph">
        Already have a design and investment? Use our Pre-flight services to
        connect your spacecraft to our RSOC via our modern, cyber secure, and
        cloud based infrastructure. Traditional space and NewSpace companies
        benefit from this robust API software infrastructure. The Pre-flight
        stage occurs in parallel to your spacecraft flight software development
        for a smooth onboarding process for your mission.
      </Base.Paragraph>
    </Link>
  );
}

export default Preflight;
