import React from 'react';
import styled from 'styled-components';

import MissionDesign from '../ServiceContent/Mission';
import Preflight from '../ServiceContent/Preflight';
import Diagnostics from '../ServiceContent/Diagnostics';
import Operations from '../ServiceContent/Operations';

const Group = styled.div`
  color: white;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;

  min-width: 296px;
  max-width: 296px;

  transition: all 250ms;

  .paragraph {
    text-align: justify;
  }

  @media only screen and (min-width: 768px) {
    min-width: 600px;
    max-width: 600px;
  }

  @media only screen and (min-width: 1024px) {
    min-width: 450px;
    max-width: 450px;

    height: 300px;
  }

  @media only screen and (min-width: 1280px) {
    min-width: 500px;
    max-width: 500px;
  }

  @media only screen and (min-width: 1440px) {
    min-width: 512px;
    max-width: 512px;
    height: auto;
  }

  @media only screen and (min-width: 1800px) {
    min-width: 768px;
    max-width: 768px;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

interface ContentContainer {
  spread?: number;
}

const ContentContainer = styled.div<ContentContainer>`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  min-width: 100%;
  min-height: 100%;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .group:not(:first-child) {
    margin-top: 1rem;
  }

  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: calc(3rem + ${({ spread }) => spread || 0} * 110vw);

    .group:not(:first-child) {
      margin: 0;
    }

    .top-left {
      align-self: flex-end;
      justify-self: flex-end;
    }

    .top-right {
      align-self: flex-end;
      justify-self: flex-start;
    }

    .bottom-left {
      align-self: flex-start;
      justify-self: flex-end;
    }

    .bottom-right {
      align-self: flex-start;
      justify-self: flex-start;
    }
  }
`;

const TabletSpread = styled.div`
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;

interface Content {
  spreadStart: number;
  spreadEnd: number;
  scrollProgress: number;
}

export default React.memo(function ({
  spreadStart,
  spreadEnd,
  scrollProgress,
}: Content) {
  const spread = (scrollProgress - spreadStart) / (spreadEnd - spreadStart);

  return (
    <ContentContainer spread={spread}>
      <Group className="group top-left">
        <MissionDesign />
      </Group>
      <Group className="group top-right">
        <Preflight />
      </Group>
      <TabletSpread style={{ height: `calc(${spread} * 110vh)` }} />
      <Group className="group bottom-left">
        <Diagnostics />
      </Group>
      <Group className="group bottom-right">
        <Operations />
      </Group>
    </ContentContainer>
  );
});
