import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';

import * as Base from 'src/page-styles/base.styled';

const Title = styled.div`
  color: white;

  display: flex;
  align-items: center;

  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;

  svg {
    margin-left: 1rem;
  }
`;

function Operations(): JSX.Element {
  return (
    <Link to="/operations" style={{ textDecoration: 'none' }}>
      <Title>
        Operations <FaRegArrowAltCircleRight />
      </Title>
      <Base.Paragraph className="paragraph">
        Saber Astronautics full stack mission operations from our Responsive
        Space Operations Center (RSOC) program. The RSOC uses a marketplace of
        global SATCOM and sensor suppliers to support your satellites, manages
        spacecraft command and data handling, and handles full space environment
        and space traffic management for a safe and secure operation from launch
        to End-of-Life.
      </Base.Paragraph>
    </Link>
  );
}

export default Operations;
