import React from 'react';
import styled from 'styled-components';

const ServiceTitle = styled.div`
  color: ${({ theme }) => theme.primary500};
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 4rem;
  font-weight: bold;

  opacity: 0;

  transform: translateX(-50%);
  transition: opacity 0.5s;

  position: absolute;
  top: 15%;
  left: 50%;

  &.revealed {
    opacity: 1;
  }
`;

interface NapkinExplanation {
  hidden: boolean;
}

export default React.memo(function ({
  hidden,
}: NapkinExplanation): JSX.Element {
  return (
    <ServiceTitle className={hidden ? '' : 'revealed'}>Services</ServiceTitle>
  );
});
