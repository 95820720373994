import React from 'react';
import styled from 'styled-components';

const Explanation = styled.div`
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 0.875rem;

  opacity: 1;

  position: absolute;
  top: 10%;
  right: 5%;

  width: 40%;

  transition: opacity 0.5s;
  z-index: 2;

  &.hidden {
    opacity: 0;
  }

  @media only screen and (min-width: 768px) {
    font-size: 1.75rem;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 2.25rem;
    right: 10%;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 3rem;
  }
`;

interface NapkinExplanation {
  hidden: boolean;
}

export default React.memo(function ({
  hidden,
}: NapkinExplanation): JSX.Element {
  return (
    <Explanation className={hidden ? 'hidden' : ''}>
      From a napkin sketch to space flight, Saber is with you every step of the
      way.
    </Explanation>
  );
});
