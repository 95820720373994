import React, { useEffect, useState } from 'react';
import CallToAction from 'src/Components/CallToAction';
import ServiceNapkinAnim from 'src/Components/ScrollAnims/ServiceNapkinAnim';
import ServiceParts from 'src/Components/ScrollAnims/ServiceParts';
import ServicePartsStatic from 'src/Components/ScrollAnims/ServicePartsStatic';
import SEO from 'src/Components/SEO';
import ImageStar from 'src/images/services/stars.png';
import Layout from 'src/Layout';
import styled, { keyframes } from 'styled-components';

interface Props {
  theme: {
    primary500: string;
  };
}

const SpaceAnim = (props: Props) => keyframes`
  0% {
    color: white;
    transform: translate(-50%, -50%) scale(5);
  }

  100% {
    color: ${props.theme.primary500};
    transform: translate(-50%, -50%) scale(1);
  }
`;

const StarFieldAnim = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const Introduction = styled.div`
  background-color: ${({ theme }) => theme.black};
  position: relative;
  overflow: hidden;

  height: 100vh;
  width: 100%;
`;

const LetsGo = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 2rem;

  position: absolute;
  left: 50%;
  top: 35%;

  transform: translateX(-50%);
  z-index: 1;

  animation: ${StarFieldAnim} 1s linear forwards;

  @media only screen and (min-width: 768px) {
    font-size: 4rem;
    top: 35%;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 6rem;
    top: 25%;
  }
`;

const Space = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 6rem;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  animation: ${SpaceAnim} 1.5s ease-in-out forwards;

  @media only screen and (min-width: 768px) {
    font-size: 12rem;
  }
`;

const StarField = styled.div`
  background-image: url(${ImageStar});
  background-size: cover;

  position: absolute;

  height: 100%;
  width: 100%;
  animation: ${StarFieldAnim} 2s linear forwards;
`;

function ServiceOverview(): JSX.Element {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.screen.width);

    function updateWidth() {
      setWidth(window.screen.width);
    }

    document.addEventListener('orientationchange', updateWidth);
    return () => document.removeEventListener('orientationchange', updateWidth);
  }, []);

  return (
    <>
      <SEO
        title="Service overview | Saber Astronautics"
        description="Mission planning and operations services from a briliant idea right through to on-orbit operations for entrepreneurs up to large corporations."
        url="https://www.saberastro.com/service-overview"
      />
      <Layout>
        <Introduction>
          <LetsGo>Let's go to</LetsGo>
          <Space>SPACE</Space>
          <StarField />
        </Introduction>
        <ServiceNapkinAnim />
        {width >= 768 ? <ServiceParts /> : <ServicePartsStatic />}
        <CallToAction
          header="Find out what services are right for you."
          linkText="Get in touch"
          to="/"
        />
      </Layout>
    </>
  );
}

export default ServiceOverview;
