import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';

import * as Base from 'src/page-styles/base.styled';

const Title = styled.div`
  color: white;

  display: flex;
  align-items: center;

  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;

  svg {
    margin-left: 1rem;
  }
`;

function Mission(): JSX.Element {
  return (
    <Link to="/mission-design" style={{ textDecoration: 'none' }}>
      <Title>
        Mission Design <FaRegArrowAltCircleRight />
      </Title>
      <Base.Paragraph className="paragraph">
        Saber has over a decade of experience in spacecraft and mission design,
        covering everything from technical factors to unravel complex designs
        between hardware requirements and constellations, to business factors
        such as revenues, returns, and risks. Use this heritage service if you
        need a new concurrent design, spectrum allocation, research proposals,
        or investment due diligence.
      </Base.Paragraph>
    </Link>
  );
}

export default Mission;
