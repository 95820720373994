import React from 'react';
import styled from 'styled-components';

import ImageNapkin from 'src/images/services/napkin.png';

const NapkinObject = styled.img`
  position: absolute;
  top: 5%;
  left: 5%;
  z-index: 0;

  height: 170px;
  width: 170px;

  @media only screen and (min-width: 768px) {
    height: 300px;
    width: 300px;
  }

  @media only screen and (min-width: 1024px) {
    height: 400px;
    width: 400px;
  }

  @media only screen and (min-width: 1200px) {
    height: 500px;
    width: 500px;
  }

  @media only screen and (min-width: 1500px) {
    height: 550px;
    width: 550px;
  }
`;

interface Napkin {
  imageStop: number;
  scrollProgress: number;
}

export default React.memo(({ imageStop, scrollProgress }: Napkin) => {
  const opacity = 1 - Math.min(scrollProgress / (100 - imageStop), 1);

  return <NapkinObject style={{ opacity }} src={ImageNapkin} />;
});
