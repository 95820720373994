import styled from 'styled-components';

export const Scroll = styled.div`
  background: ${({ theme }) => theme.black};

  position: sticky;
  left: 0;
  top: 0;

  height: 100vh;
  width: 100%;

  overflow: hidden;
  transition: all 0.5s;
`;

export const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;
  height: 200vh;
`;
