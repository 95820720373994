import React from 'react';
import styled from 'styled-components';

import * as Base from 'src/page-styles/base.styled';

const Wrapper = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 2rem;
  text-align: center;

  position: absolute;
  left: 50%;
  top: 50%;

  width: 75%;

  @media only screen and (min-width: 768px) {
    font-size: 4rem;
  }
`;

interface PersonalisedTitle {
  spreadStart: number;
  spreadEnd: number;
  scrollProgress: number;
}

export default React.memo(function ({
  spreadStart,
  spreadEnd,
  scrollProgress,
}: PersonalisedTitle) {
  const progress = (scrollProgress - spreadStart) / (spreadEnd - spreadStart);

  return (
    <Wrapper
      style={{
        opacity: progress,
        transform: `translate(-50%, -50%) scale(${progress})`,
      }}
    >
      Space flight
      <Base.Yellow style={{ display: 'block' }}>personalised.</Base.Yellow>
    </Wrapper>
  );
});
